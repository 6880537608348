// All Keyframe Animations 
@keyframes spinner-animation {
    0% {
    transform: rotate(0deg);
    }
    100% {
    transform: rotate(360deg);
    }
}

@keyframes slidein {
    from {
      opacity: 0;
      transform: translate(-50%, -1000px);
    }
  
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

@keyframes slideUp {
    from {
        top: 95%;
        opacity: 1;
    }
  
    to {
        top: 70px;  
        opacity: 0;
    }
  }

@keyframes fade {
    0%   {opacity: 1; display: block;}
    50%  {opacity: 1; display: block;}
    75%  {opacity: 0; display: block;}
    100% {opacity: 0; display: none;}
}

@keyframes fadePrompt {
    0%   {opacity: 0}
    50%  {opacity: 0.25}
    75%  {opacity: 0.75}
    100% {opacity: 0.95}
}
@keyframes slideFromBottom {
    0%   {top: 100%}
    100% {top: 50%}
}
